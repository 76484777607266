import React from "react";
import Modal from "../common/Modal";
import { ModalType } from "../../typings/ModalType";
import avatar from "../../assets/images/avatar.png";
import avatar2 from "../../assets/images/avatar2.png";
import avLogo from "../../assets/images/av-logo.png";

type Props = {
  onClose: () => void;
};

const NotificationsManager = ({ onClose }: Props) => {
  return (
    <Modal onClose={onClose} type={ModalType.NOTIFICATION}>
      <div className="notify-modal">
        <h4>Notifications</h4>
        <button onClick={onClose} type="button" className="btn-close"></button>
        <div className="notify-push">
          <div className="notify-push_left">
            <p className="title">Push notifications</p>
            Automatically send new notifications
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
        <div className="notify-one new">
          <div className="notify-one_content">
            <figure className="user-icon green">
              <img src="" alt="" style={{ display: "none" }} />
              <span className="name">JB</span>
            </figure>
            <p className="user-name">
              <span>Jason Bedge</span>
            </p>
            Status changed for “Water meter damage” to{" "}
            <span className="status violet">To approve</span>
          </div>
          <div className="notify-one_btn">
            <button type="button" className="btn btn-border">
              Review
            </button>
            <button type="button" className="btn">
              Approve
            </button>
          </div>
        </div>
        <div className="notify-one new">
          <div className="notify-one_content">
            <figure className="user-icon">
              <img src={avatar} alt="" />
              <span className="name" style={{ display: "none" }}>
                JA
              </span>
            </figure>
            <p className="user-name">
              <span>Ryana Gouse</span>
            </p>
            New report for Block A, 7th floor, 3rd apt{" "}
            <span className="status orange">Damage</span>
          </div>
          <div className="notify-one_btn">
            <button type="button" className="btn btn-border">
              Review
            </button>
            <button type="button" className="btn">
              Approve
            </button>
          </div>
        </div>
        <div className="notify-one new">
          <div className="notify-one_content">
            <figure className="user-icon">
              <img src={avLogo} alt="" />
              <span className="name" style={{ display: "none" }}>
                JA
              </span>
            </figure>
            <p className="user-name">
              <span>Management Company</span>
            </p>
            Posted a new post. Check it out!
          </div>
        </div>
        <div className="notify-one">
          <div className="notify-one_content">
            <figure className="user-icon">
              <img src={avatar2} alt="" />
              <span className="name" style={{ display: "none" }}>
                JA
              </span>
            </figure>
            <p className="user-name">
              <span>Emery Vaccaro</span>
            </p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </div>
        </div>
        <p className="notify-modal_footer">
          <button type="button" className="btn">
            Show all notifications
          </button>
        </p>
      </div>
    </Modal>
  );
};

export default NotificationsManager;
