import React from "react";
import { NavLink } from "react-router-dom";
import { useMoreThen1200 } from "../utils/mediaQuery";
import useToggle from "../hooks/useToggle";
import MenuManager from "./MenuManager";
import NotificationsManager from "./modals/NotificationsManager";
import logo from "../assets/images/logo.svg";
import messageIcon from "../assets/images/icon-message.svg";
import ringIcon from "../assets/images/icon-ring.svg";
import avatar from "../assets/images/avatar.png";
import avLogo from "../assets/images/av-logo.png";
import avatar2 from "../assets/images/avatar2.png";
import menuIcon from "../assets/images/menu-burger.svg";
import settingsIcon from "../assets/images/menu/icon-settings.svg";
import logoutIcon from "../assets/images/menu/icon-logout.svg";

const HeaderManager = () => {
  const [showNotifications, toggleNotifications] = useToggle(false);
  const [showMobileMenu, toggleMobileMenu] = useToggle(false);
  const isMoreThen1200 = useMoreThen1200();

  return (
    <>
      <header className="navbar navbar-expand-xl fixed-top header">
        <div className="container-fluid">
          <div className="header-left">
            <NavLink to="/" className="navbar-brand">
              <img src={logo} alt="logo" />
            </NavLink>
            <button
              onClick={toggleMobileMenu}
              className="navbar-toggler"
              type="button"
            >
              <img src={menuIcon} alt="" />
            </button>
            {(showMobileMenu || isMoreThen1200) && (
              <MenuManager onClose={toggleMobileMenu} />
            )}
          </div>

          <div className="header-right">
            <NavLink to="messages" className="btn-message">
              <img src={messageIcon} alt="" />
            </NavLink>
            <a onClick={toggleNotifications} className="btn-notify">
              <img src={ringIcon} alt="" />
            </a>

            <div className="dropdown header-user">
              <div className="dropdown-toggle" data-bs-toggle="dropdown">
                <div className="header-user_pic">
                  <figure className="user-icon">
                    <img src="" alt="" style={{ display: "none" }} />
                    <span className="name">JA</span>
                  </figure>
                  <span className="header-user_status online"></span>
                </div>
                <div className="header-user_name">
                  <a href="#" className="name">
                    Jessica Anderson
                  </a>
                  Property manager
                </div>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    <span className="icon">
                      <img src={settingsIcon} alt="" />
                    </span>
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    <span className="icon">
                      <img src={logoutIcon} alt="" />
                    </span>
                    Log out
                  </a>
                </li>
              </ul>
              {/* TODO: move in own component */}
              <div className="notify-modal" style={{ display: "none" }}>
                <h4>Notifications</h4>
                <div className="notify-push">
                  <div className="notify-push_left">
                    <p className="title">Push notifications</p>
                    Automatically send new notifications
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>

                <div className="notify-one">
                  <div className="notify-one_content">
                    <figure className="user-icon green">
                      <img src="" alt="" style={{ display: "none" }} />
                      <span className="name">JB</span>
                    </figure>
                    <p className="user-name">Jason Bedge</p>
                    Status changed for “Water meter damage” to{" "}
                    <span className="status violet">To approve</span>
                  </div>
                  <div className="notify-one_btn">
                    <button type="button" className="btn btn-border">
                      Review
                    </button>
                    <button type="button" className="btn">
                      Approve
                    </button>
                  </div>
                </div>
                <div className="notify-one">
                  <div className="notify-one_content">
                    <figure className="user-icon">
                      <img src={avatar} alt="" />
                      <span className="name" style={{ display: "none" }}>
                        JA
                      </span>
                    </figure>
                    <p className="user-name">Ryana Gouse</p>
                    New report for Block A, 7th floor, 3rd apt{" "}
                    <span className="status orange">Damage</span>
                  </div>
                  <div className="notify-one_btn">
                    <button type="button" className="btn btn-border">
                      Review
                    </button>
                    <button type="button" className="btn">
                      Approve
                    </button>
                  </div>
                </div>
                <div className="notify-one">
                  <div className="notify-one_content">
                    <figure className="user-icon">
                      <img src={avLogo} alt="" />
                      <span className="name" style={{ display: "none" }}>
                        JA
                      </span>
                    </figure>
                    <p className="user-name">Management Company</p>
                    Posted a new post. Check it out!
                  </div>
                </div>
                <div className="notify-one">
                  <div className="notify-one_content">
                    <figure className="user-icon">
                      <img src={avatar2} alt="" />
                      <span className="name" style={{ display: "none" }}>
                        JA
                      </span>
                    </figure>
                    <p className="user-name">Emery Vaccaro</p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </div>
                </div>
                <p className="notify-modal_footer">
                  <button type="button" className="btn">
                    Show all notifications
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showNotifications && (
        <NotificationsManager onClose={toggleNotifications} />
      )}
    </>
  );
};

export default HeaderManager;
