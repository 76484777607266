import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

/* Wrapper component used to scroll to top on route change with react router dom v6 */
const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default Wrapper;
