import { useMediaQuery } from "react-responsive";

//Data with media queries from _variables.scs
/* $xxxl-hd:				3840px;
$xxl-hd:				2800px;
$xl-hd:				    2000px;
$l-hd:					1600px;
$m-hd:					1400px;
$hd:					1199px;
$desktop:				1024px;
$tablet: 				991px;
$tablet-middle: 		830px;
$tablet-small:          767px;
$smartphone:			600px;
$smartphone-middle:		575px;
$smartphone-small:		480px;
$smartphone-smallest:	375px; */

export const useLessThen991 = () =>
  useMediaQuery({ query: "(max-width: 991px)" });

export const useMoreThen1200 = () =>
  useMediaQuery({ query: "(min-width: 1200px)" });
