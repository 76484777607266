import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SharedLayout from "./components/SharedLayout";
import { SharedLayoutType } from "./typings/SharedLayoutType";
const NewsFeedPage = lazy(() => import("./views/NewsFeedPage"));
const MySpacePage = lazy(() => import("./views/MySpacePage"));
const MessagesPage = lazy(() => import("./views/MessagesPage"));
const ChatPageMobile = lazy(() => import("./views/mobile/ChatPageMobile"));
const DocumentsPage = lazy(() => import("./views/DocumentsPage"));
const PaymentsPage = lazy(() => import("./views/PaymentsPage"));
const UtilitiesPage = lazy(() => import("./views/UtilitiesPage"));
const DashboardPage = lazy(() => import("./views/DashboardPage"));
const NewsManagementPage = lazy(() => import("./views/NewsManagementPage"));
const PropertiesPage = lazy(() => import("./views/PropertiesPage"));
const PropertyItemPage = lazy(() => import("./views/PropertyItemPage"));
const InfrastructurePage = lazy(() => import("./views/InfrastructurePage"));
const TenantsPage = lazy(() => import("./views/TenantsPage"));
const StaffPage = lazy(() => import("./views/StaffPage"));
const TasksPage = lazy(() => import("./views/TasksPage"));
const ReportsPage = lazy(() => import("./views/ReportsPage"));
const NotFoundPage = lazy(() => import("./views/NotFoundPage"));

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<SharedLayout type={SharedLayoutType.TENANT} />}
        >
          <Route index element={<NewsFeedPage />} />
          <Route path="news_feed" element={<NewsFeedPage />} />
          <Route path="my_space" element={<MySpacePage />} />
          <Route path="messages" element={<MessagesPage />} />
          <Route path="messages/:chatId" element={<ChatPageMobile />} />
          <Route path="documents" element={<DocumentsPage />} />
          <Route path="payments" element={<PaymentsPage />} />
          <Route path="utilities" element={<UtilitiesPage />} />

          <Route
            path="manage"
            element={<SharedLayout type={SharedLayoutType.MANAGER} />}
          >
            <Route index element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="news_feed" element={<NewsFeedPage />} />
            <Route
              path="news_feed/management"
              element={<NewsManagementPage />}
            />
            <Route path="properties" element={<PropertiesPage />} />
            <Route
              path="properties/:propertyId"
              element={<PropertyItemPage />}
            />
            <Route path="infrastructure" element={<InfrastructurePage />} />
            <Route path="tenants" element={<TenantsPage />} />
            <Route path="staff" element={<StaffPage />} />
            <Route path="tasks" element={<TasksPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="messages" element={<MessagesPage />} />
            <Route path="messages/:chatId" element={<ChatPageMobile />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
