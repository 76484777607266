import React from "react";
import Modal from "../common/Modal";
import { ModalType } from "../../typings/ModalType";
import avatar2 from "../../assets/images/avatar2.png";
import avLogo from "../../assets/images/av-logo.png";
import avLogo2 from "../../assets/images/av-logo2.jpg";

type Props = {
  onClose: () => void;
};

const NotificationsTenant = ({ onClose }: Props) => {
  return (
    <Modal onClose={onClose} type={ModalType.NOTIFICATION}>
      <div className="notify-modal">
        <h4>Notifications</h4>
        <button onClick={onClose} type="button" className="btn-close"></button>
        <div className="notify-push">
          <div className="notify-push_left">
            <p className="title">Push notifications</p>
            Automatically send new notifications
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
          </div>
        </div>
        <div className="notify-one">
          <div className="notify-one_content">
            <figure className="user-icon green">
              <img src={avLogo2} alt="" />
              <span className="name" style={{ display: "none" }}></span>
            </figure>
            <p className="user-name">Water damage</p>
            <p>
              Your request status has been changed to{" "}
              <span className="status green">Completed</span>
            </p>
            Please, let us know how was your experience with this request!
          </div>
          <button type="button" className="btn">
            Leave a feedback
          </button>
        </div>
        <div className="notify-one">
          <div className="notify-one_content">
            <figure className="user-icon">
              <img src={avLogo} alt="" />
              <span className="name" style={{ display: "none" }}>
                JA
              </span>
            </figure>
            <p className="user-name">Management Company</p>
            Posted a new post. Check it out!
          </div>
        </div>
        <div className="notify-one">
          <div className="notify-one_content">
            <figure className="user-icon">
              <img src={avatar2} alt="" />
              <span className="name" style={{ display: "none" }}>
                JA
              </span>
            </figure>
            <p className="user-name">Emery Vaccaro</p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </div>
        </div>
        <p className="notify-modal_footer">
          <button type="button" className="btn">
            Show all notifications
          </button>
        </p>
      </div>
    </Modal>
  );
};

export default NotificationsTenant;
