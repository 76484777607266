import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalType } from "../../typings/ModalType";

const modalRoot = document.getElementById("modal-root") as HTMLDivElement;

type Props = {
  onClose: () => void;
  children: ReactNode;
  type?: ModalType;
};

const Modal = (props: Props) => {
  const { onClose: closeModal, type = ModalType.MODAL, children } = props;

  const setClassName = () => {
    switch (type) {
      case ModalType.MODAL: {
        return "modal-overlay";
      }
      case ModalType.POPUP: {
        return "popup-overlay";
      }
      case ModalType.NOTIFICATION: {
        return "notification-overlay";
      }
      default:
        return "";
    }
  };

  useEffect(() => {
    /* Closing the modal by Esc key */
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.body.style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "unset";
    };
  }, [closeModal]);

  /* Closing the modal by clicking outside the modal */
  const handleClick = (event: any) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return createPortal(
    <div className={setClassName()} onClick={handleClick}>
      {children}
    </div>,
    modalRoot
  );
};

export default Modal;
