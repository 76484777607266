import React from "react";
import { NavLink } from "react-router-dom";
import closeWIcon from "../assets/images/icon-close_w.svg";
import dashboardIcon from "../assets/images/menu/icon-dashboard.svg";
import dashboardActiveIcon from "../assets/images/menu/icon-dashboard_active.svg";
import messagesIcon from "../assets/images/menu/icon-messages.svg";
import messagesActiveIcon from "../assets/images/menu/icon-messages_active.svg";
import newsIcon from "../assets/images/menu/icon-news.svg";
import newsActiveIcon from "../assets/images/menu/icon-news_active.svg";
import propertiesIcon from "../assets/images/menu/icon-properties.svg";
import propertiesActiveIcon from "../assets/images/menu/icon-properties_active.svg";
import structureIcon from "../assets/images/menu/icon-structure.svg";
import structureActiveIcon from "../assets/images/menu/icon-structure_active.svg";
import tenantsIcon from "../assets/images/menu/icon-tenants.svg";
import tenantsActiveIcon from "../assets/images/menu/icon-tenants_active.svg";
import staffIcon from "../assets/images/menu/icon-staff.svg";
import staffActiveIcon from "../assets/images/menu/icon-staff_active.svg";
import tasksIcon from "../assets/images/menu/icon-tasks.svg";
import tasksActiveIcon from "../assets/images/menu/icon-tasks_active.svg";
import settingsIcon from "../assets/images/menu/icon-settings.svg";
import settingsActiveIcon from "../assets/images/menu/icon-settings_active.svg";
import logoutIcon from "../assets/images/menu/icon-logout.svg";
import logoutActiveIcon from "../assets/images/menu/icon-logout_active.svg";
type Props = {
  onClose: () => void;
};

const MenuManager = ({ onClose }: Props) => {
  return (
    <div className="navbar-collapse">
      <div className="header-user mobile">
        <div className="header-user_pic">
          <figure className="user-icon">
            <img src="" alt="" style={{ display: "none" }} />
            <span className="name">JA</span>
          </figure>
        </div>
        <div className="header-user_name">
          <a href="#" className="name">
            Jessica Anderson
          </a>
          Property manager
        </div>
        <button onClick={onClose} className="header-user_close" type="button">
          <img src={closeWIcon} alt="" />
        </button>
      </div>

      <ul className="navbar-nav">
        <li onClick={onClose} className="nav-item">
          <NavLink to="dashboard" className="nav-link">
            <span className="icon">
              <img src={dashboardIcon} className="simple" alt="" />
              <img src={dashboardActiveIcon} className="active" alt="" />
            </span>
            Dashboard
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item mobile">
          <NavLink to="messages" className="nav-link">
            <span className="icon">
              <img src={messagesIcon} className="simple" alt="" />
              <img src={messagesActiveIcon} className="active" alt="" />
            </span>
            Messages
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="news_feed" className="nav-link">
            <span className="icon">
              <img src={newsIcon} className="simple" alt="" />
              <img src={newsActiveIcon} className="active" alt="" />
            </span>
            News feed
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="properties" className="nav-link">
            <span className="icon">
              <img src={propertiesIcon} className="simple" alt="" />
              <img src={propertiesActiveIcon} className="active" alt="" />
            </span>
            Properties
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="infrastructure" className="nav-link">
            <span className="icon">
              <img src={structureIcon} className="simple" alt="" />
              <img src={structureActiveIcon} className="active" alt="" />
            </span>
            Infrastructure
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="tenants" className="nav-link">
            <span className="icon">
              <img src={tenantsIcon} className="simple" alt="" />
              <img src={tenantsActiveIcon} className="active" alt="" />
            </span>
            Tenants
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="staff" className="nav-link">
            <span className="icon">
              <img src={staffIcon} className="simple" alt="" />
              <img src={staffActiveIcon} className="active" alt="" />
            </span>
            Staff
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="tasks" className="nav-link">
            <span className="icon">
              <img src={tasksIcon} className="simple" alt="" />
              <img src={tasksActiveIcon} className="active" alt="" />
            </span>
            Tasks
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item mobile">
          <a className="nav-link" href="#">
            <span className="icon">
              <img src={settingsIcon} className="simple" alt="" />
              <img src={settingsActiveIcon} className="active" alt="" />
            </span>
            Settings
          </a>
        </li>
      </ul>
      <button onClick={onClose} className="btn-logout">
        <span className="icon">
          <img src={logoutIcon} className="simple" alt="" />
          <img src={logoutActiveIcon} className="active" alt="" />
        </span>
        Log out
      </button>
    </div>
  );
};

export default MenuManager;
