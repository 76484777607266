import React, { Suspense } from "react";
import HeaderManager from "./HeaderManager";
import { Outlet } from "react-router-dom";
import { SharedLayoutType } from "../typings/SharedLayoutType";
import HeaderTenant from "./HeaderTenant";

type Props = {
  type: SharedLayoutType;
};

const SharedLayout = ({ type }: Props) => {
  return (
    <>
      {type === SharedLayoutType.MANAGER && <HeaderManager />}
      {type === SharedLayoutType.TENANT && <HeaderTenant />}
      {/* TODO: Spinner or etc. */}
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  );
};

export default SharedLayout;
