import React from "react";
import { NavLink } from "react-router-dom";
import messageIcon from "../assets/images/icon-message.svg";
import avatar from "../assets/images/avatar.png";
import closeWIcon from "../assets/images/icon-close_w.svg";
import dashboardIcon from "../assets/images/menu/icon-dashboard.svg";
import dashboardActiveIcon from "../assets/images/menu/icon-dashboard_active.svg";
import messagesActiveIcon from "../assets/images/menu/icon-messages_active.svg";
import newsIcon from "../assets/images/menu/icon-news.svg";
import newsActiveIcon from "../assets/images/menu/icon-news_active.svg";
import settingsIcon from "../assets/images/menu/icon-settings.svg";
import settingsActiveIcon from "../assets/images/menu/icon-settings_active.svg";
import docIcon from "../assets/images/menu/icon-doc.svg";
import docActiveIcon from "../assets/images/menu/icon-doc_active.svg";
import payIcon from "../assets/images/menu/icon-pay.svg";
import payActiveIcon from "../assets/images/menu/icon-pay_active.svg";
import utilIcon from "../assets/images/menu/icon-util.svg";
import utilActiveIcon from "../assets/images/menu/icon-util_active.svg";
import logoutIcon from "../assets/images/menu/icon-logout.svg";
import logoutActiveIcon from "../assets/images/menu/icon-logout_active.svg";

type Props = {
  onClose: () => void;
};

const MenuTenant = ({ onClose }: Props) => {
  return (
    <div className="navbar-collapse">
      <div className="header-user mobile">
        <div className="header-user_pic">
          <figure className="user-icon">
            <img src={avatar} alt="" />
            <span className="name" style={{ display: "none" }}>
              RG
            </span>
          </figure>
        </div>
        <div className="header-user_name">
          <a href="#" className="name">
            Rayna Gouse
          </a>
          Tenant
        </div>
        <button onClick={onClose} className="header-user_close" type="button">
          <img src={closeWIcon} alt="" />
        </button>
      </div>
      <ul className="navbar-nav">
        <li onClick={onClose} className="nav-item">
          <NavLink to="news_feed" className="nav-link">
            <span className="icon">
              <img src={newsIcon} className="simple" alt="" />
              <img src={newsActiveIcon} className="active" alt="" />
            </span>
            News feed
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="my_space" className="nav-link">
            <span className="icon">
              <img src={dashboardIcon} className="simple" alt="" />
              <img src={dashboardActiveIcon} className="active" alt="" />
            </span>
            My spaces
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item mobile">
          <NavLink to="messages" className="nav-link">
            <span className="icon">
              <img src={messageIcon} className="simple" alt="" />
              <img src={messagesActiveIcon} className="active" alt="" />
            </span>
            Messages
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="documents" className="nav-link">
            <span className="icon">
              <img src={docIcon} className="simple" alt="" />
              <img src={docActiveIcon} className="active" alt="" />
            </span>
            Documents
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="payments" className="nav-link">
            <span className="icon">
              <img src={payIcon} className="simple" alt="" />
              <img src={payActiveIcon} className="active" alt="" />
            </span>
            Payments
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item">
          <NavLink to="utilities" className="nav-link">
            <span className="icon">
              <img src={utilIcon} className="simple" alt="" />
              <img src={utilActiveIcon} className="active" alt="" />
            </span>
            Utilities
          </NavLink>
        </li>
        <li onClick={onClose} className="nav-item mobile">
          <a className="nav-link" href="#">
            <span className="icon">
              <img src={settingsIcon} className="simple" alt="" />
              <img src={settingsActiveIcon} className="active" alt="" />
            </span>
            Settings
          </a>
        </li>
      </ul>
      <button onClick={onClose} className="btn-logout">
        <span className="icon">
          <img src={logoutIcon} className="simple" alt="" />
          <img src={logoutActiveIcon} className="active" alt="" />
        </span>
        Log out
      </button>
    </div>
  );
};

export default MenuTenant;
